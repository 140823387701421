<script>
  import useJwt from '@/auth/jwt/useJwt'
  import BCardCode from '@core/components/b-card-code/BCardCode'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Cleave from 'vue-cleave-component'
  import Ripple from 'vue-ripple-directive'

  export default {
    directives: {
      Ripple,
    },
    props: {
      token: {
        type: String,
        default: null,
      },
    },
    components: {
      BCardCode,
      Cleave,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        codigo: '',
        contrato: undefined,
        assinatura: {
          testemunhas: [],
          gestor: {},
          tomador: {},
        },
        table: {
          items: [],
          fields: [
            { key: 'nome', label: 'Nome', sortable: true },
            { key: 'assinouEm', label: 'Data', sortable: true },
            { key: 'papel', label: 'Papel', sortable: true },
            { key: 'assinou', label: 'Assinou', sortable: true },
            { key: 'url', label: 'Nº Contrato', sortable: true },
          ],
          isBusy: false,
          sort: {
            by: '',
            desc: false,
            direction: 'asc',
          },
          styles: {
            status: [
              {
                GRPEPago: 'success',
                GRPEGerado: 'warning',
                GRPEExpirado: 'info',
              },
            ],
          },
        },
        pesquisar: {
          pagination: {
            currentPage: 1,
            total: 0,
            perPage: 10,
            options: [10, 25, 50, 100],
          },
          isBusy: false,
        },
      }
    },
    methods: {
      async verificar(token) {
        this.testemunhas = []
        this.gestor = {}
        this.tomador = {}
        this.table.items = []
        this.pesquisar.isBusy = true

        const { contrato, url } = (await useJwt.get(`financeiro-assinatura/verifica/assinatura/${token}`)).data

        if (contrato) {
          this.$message.success('Assinaturas verificadas com sucesso.')
          this.contrato = {
            contrato,
            url,
          }

          const testemunhas = contrato.AssinaturasSobFinanceiro.filter(
            (assinatura) => assinatura.usuario.tipoAssinatura == 'testemunha',
          )

          const gestor = contrato.AssinaturasSobFinanceiro.filter(
            (assinatura) => assinatura.usuario.tipoAssinatura == 'gestor',
          )[0]

          const tomador = contrato.segurado.tomador
          this.assinatura.gestor = {
            nome: gestor.usuario.name,
            assinou: gestor.assinouFinanceiro,
            assinouEm: gestor.assinouEm,
            papel: 'Gestor',
          }

          this.assinatura.testemunhas = testemunhas.map((testemunha) => ({
            nome: testemunha.usuario.name,
            assinou: testemunha.assinouFinanceiro,
            assinouEm: testemunha.assinouEm,
            papel: 'Testemunha',
          }))

          this.assinatura.tomador = {
            nome: tomador.nomeCompleto,
            assinou: contrato.tomadorAssinou,
            assinouEm: contrato.tomadorAssinouEm,
            papel: 'Tomador',
          }

          this.table.items = [this.assinatura.gestor, ...this.assinatura.testemunhas, this.assinatura.tomador]
        } else {
          this.$message.error('Código inválido.')
        }

        this.pesquisar.isBusy = false
      },
      isValidUrl(url) {
        try {
          const parsedUrl = new URL(url)
          return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:'
        } catch (erro) {
          console.error(erro)
          return false
        }
      },
    },
    mounted() {
      this.codigo = this.token
    },
  }
</script>

<template>
  <div class="tw-flex tw-items-center tw-justify-center tw-align-center tw-h-screen px-2">
    <b-card-code>
      <b-card-body>
        <b-row>
          <b-col md="12">
            <h4 class="mb-1 tw-text-center tw-flex tw-items-center tw-flex-col">
              <feather-icon icon="FileTextIcon" size="55" class="tw-text-center mb-2" />
              Verificação das Assinaturas de Contrato
            </h4>

            <b-form-group label-for="codigoResponsavel">
              <validation-provider #default="{ errors }" name="Código" rules="required">
                <label class="tw-text-gray-600 tw-text-center" for="codigoResponsavel">Insira o código de verificação</label>
                <b-form-input
                  id="codigoResponsavel"
                  placeholder="Código de Verificação"
                  type="number"
                  v-model.number="codigo"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <transition name="fade" mode="out-in">
            <b-col key="1" md="12" class="tw-flex">
              <b-button
                class="tw-flex-grow"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="!codigo"
                @click="verificar(codigo)"
              >
                <span v-if="!pesquisar.isBusy">Verificar</span>
                <b-spinner v-if="pesquisar.isBusy" class="align-middle mr-1" small />
              </b-button>
            </b-col>
          </transition>

          <b-col>
            <b-table
              v-show="table.items.length > 0"
              id="tableGRPE"
              striped
              responsive
              class="position-relative tw-mt-1"
              :per-page="pesquisar.pagination.perPage"
              :items="table.items"
              :fields="table.fields"
              :sort-by.sync="table.sort.by"
              :sort-desc.sync="table.sort.desc"
              :sort-direction="table.sort.direction"
              :busy="table.isBusy"
              :current-page="pesquisar.pagination.currentPage"
            >
              <template v-slot:table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-1"></b-spinner>
                  <strong>Carregando...</strong>
                </div>
              </template>

              <template #cell(nome)="row">
                <span class="tw-text-gray-600 tw-font-medium">{{ row.item.nome }}</span>
              </template>

              <template #cell(assinouEm)="row">
                <span class="tw-text-gray-600" v-if="row.item.assinouEm">
                  {{
                    new Intl.DateTimeFormat('pt-BR', {
                      dateStyle: 'short',
                      timeStyle: 'long',
                      timeZone: 'Australia/Sydney',
                    }).format(new Date(row.item.assinouEm))
                  }}
                </span>
              </template>

              <template #cell(papel)="row">
                <b-badge v-if="row.item.papel == 'Gestor'" variant="primary" class="tw-w-full">
                  {{ row.item.papel }}
                </b-badge>
                <b-badge v-else-if="row.item.papel == 'Testemunha'" variant="warning" class="tw-w-full">
                  {{ row.item.papel }}
                </b-badge>
                <b-badge v-else-if="row.item.papel == 'Tomador'" variant="info" class="tw-w-full">
                  {{ row.item.papel }}
                </b-badge>
              </template>

              <template #cell(assinou)="row">
                <feather-icon
                  v-if="row.item.assinou"
                  icon="CheckCircleIcon"
                  color="green"
                  size="22"
                  :id="'tomador' + row.index + row.item.financeiroId"
                />
                <feather-icon
                  v-else
                  icon="XCircleIcon"
                  :id="'tomador' + row.index + row.item.financeiroId"
                  color="red"
                  size="22"
                />
              </template>

              <template #cell(url)="row">
                <a v-if="isValidUrl(contrato.url)" :href="contrato.url" target="_blank" class="tw-text-gray-600">
                  <feather-icon icon="FileTextIcon" size="22" style="color: #0070ff" />
                </a>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card-code>
  </div>
</template>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
